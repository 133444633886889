import React from 'react';

const Members = () => {
  return (
    <div>
      <h1>Under Construction</h1>
    </div>
  );
}

export default Members;
